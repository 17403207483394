/**
*
* All the variables for global style
*
*/

$breakpoint-xsm: 400px; // extra small
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$background: var(--ion-color-background-primary);
$text-primary: var(--ion-color-text-primary);
$brand-primary: var(--ion-color-brand-primary);
$text-tertiary: var(--ion-color-text-tertiary);
$text-secondary: var(--ion-color-text-secondary);

// have just introduced this here, not sure it needed to be theme-able
// if this needs to have different colors for themes then need to move it into the themes
// till then, it can stay here
$droplet-notification: rgba(149, 177, 173, 0.1);

$text-opacity: 0.6;

$box-shadow:
  0px 10px 20px -10px rgba(var(--ion-color-brand-primary-rgb), 0.1),
  0px 2px 3px rgba(var(--ion-color-brand-primary-rgb), 0.05);
$card-border-radius: 12px;

$button-shadow: var(--button-shadow);

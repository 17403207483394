/**
* BORDER STYLES
*
* Plan is to hold all the styles related the borders in here
*
*/

/**
* Creating border classes from 1 to 10
* border-1 || .b-1 ... up to 10
*/

@for $i from 1 through 10 {
  .border-#{$i},
  .b-#{$i} {
    border-style: var(--border-type);
    border-color: var(--ion-border-color);
    border-width: #{$i}px;
  }
  .border-top-#{$i},
  .bt-#{$i} {
    border-top-style: var(--border-type);
    border-top-color: var(--ion-border-color);
    border-top-width: #{$i}px;
  }
  .border-bottom-#{$i},
  .bb-#{$i} {
    border-bottom-style: var(--border-type);
    border-bottom-color: var(--ion-border-color);
    border-bottom-width: #{$i}px;
  }
  // this will add border bottom of the first element[all] in the list
  // only use this if you want to add border bottom to the elements/components in the list
  // but want to exclude the last element to not to have border bottom
  // has to be used on a parent container to give border to all the first child in that container
  // for more info look into css selector props
  .border-bottom-#{$i}-child-except-last,
  .bb-#{$i}-child-except-last {
    & > * {
      border-bottom-style: var(--border-type);
      border-bottom-color: var(--ion-border-color);
      border-bottom-width: #{$i}px;
    }
    & > *:last-child {
      border-bottom: none;
    }
  }
  .border-left-#{$i},
  .bl-#{$i} {
    border-left-style: var(--border-type);
    border-left-color: var(--ion-border-color);
    border-left-width: #{$i}px;
  }
  .border-right-#{$i},
  .br-#{$i} {
    border-right-style: var(--border-type);
    border-right-color: var(--ion-border-color);
    border-right-width: #{$i}px;
  }
}

.border-bottom-1-white,
.bb-1-white {
  border-bottom-style: var(--border-type);
  border-bottom-color: white;
  border-bottom-width: 1px;
}

.br-1-dashed {
  border-right: 1px dashed var(--ion-border-color);
}

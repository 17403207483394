/**
*
* Material style
*
*/
@use '@angular/material' as mat;

// using this function to overwrite the font-family of the material
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'inherit',
);

$my-theme: mat.m2-define-light-theme(
  (
    typography: $custom-typography,
  )
);

@include mat.all-component-themes($my-theme);

.md-like-swidin-input {
  --border-radius: 0.4em 0.4em 0 0;
  --background: rgba(var(--ion-color-text-secondary-rgb), 0.1);

  &.item-interactive.ion-valid {
    --highlight-background: var(--ion-color-base) !important;
  }

  &.item-interactive.ion-invalid.ion-pristine.item-has-focus {
    --highlight-background: var(--ion-color-base) !important;
  }

  &.item-has-focus.ion-invalid.ion-dirty ion-label {
    color: var(--ion-color-danger) !important;
  }

  &.item-has-focus ion-label {
    color: var(--ion-color-base) !important;
  }
}

@import './custom-variables';
/**
*
* All font sizes, weights, paddings, margins
* have added width for now, maybe need to create a seperate file for width, height...
*
*/

/***
* New global dynamically generated sizes and things...
*/
// Font Weight: .fw-100, .fw-200, ..., .fw-900
// Font size: .fs-01em, .fs-02em, ..., .fs-1em
// Font size: .fs-1, .fs-2, ..., .fs-30
//
// margins from -10pm to 30px & then from 30px to 100px with interval of 5, i.e., 30, 35, 40, ... 100
// Margin: .margin--1, .margin--2, .margin-1, .margin-2, ..., .margin-30, margin-35, ...., margin-100 OR: .m--1, .m--2, .m-1, .m-2, ..., .m-30, .m-35, ...., m-100
// Margin Top: .mt--1, .mt--2, .mt-1, .mt-2, ..., .mt-30, mt-35, ...., mt-100
// Margin Right: .mr--1, .mr--2, .mr-1, .mr-2, ..., .mr-30, mr-35, ...., mr-100
// Margin Bottom: .mb--1, .mb--2, .mb-1, .mb-2, ..., .mb-30, mb-35, ...., mb-100
// Margin Left: .ml--1, .ml--2, .ml-1, .ml-2, ..., .ml-30, ml-35, ...., ml-100
//
// paddings from -10px to 30px & then from 30px to 100px with interval of 5, i.e., 30, 35, 40, ... 100
// Padding: .padding--1, .padding--2, .padding-1, .padding-2, ..., .padding-30, padding-35, ...., padding-100 OR: .p--1, .p--2, .p-1, .p-2, ..., .p-30, .p-35, ...., p-100
// Padding Top: .pt--1, .pt--2, .pt-1, .pt-2, ..., .pt-30, pt-35, ...., pt-100
// Padding Right: .pr--1, .pr--2, .pr-1, .pr-2, ..., .pr-30, pr-35, ...., pr-100
// Padding Bottom: .pb--1, .pb--2, .pb-1, .pb-2, ..., .pb-30, pb-35, ...., pb-100
// Padding Left: .pl--1, .pl--2, .pl-1, .pl-2, ..., .pl-30, pl-35, ...., pl-100
//
// Border radius: .br-1, .br-2, ... .br-30
@for $i from -30 through 30 {
  // create margin from -10px to 30px
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  .margin-#{$i},
  .m-#{$i} {
    margin: #{$i}px !important;
  }

  // create paddings from -10px to 30px
  .padding-#{$i},
  .p-#{$i} {
    padding: #{$i}px !important;
    --padding: #{$i}px !important;
  }
  .pt-#{$i} {
    padding-top: #{$i}px !important;
    --padding-top: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px !important;
    --padding-end: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
    --padding-bottom: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px !important;
    --padding-start: #{$i}px !important;
  }

  @if ($i > 0) {
    // create border radius from 1px to 30px
    .b-radius-#{$i},
    .border-radius-#{$i} {
      border-radius: #{$i}px !important;
    }
    @if ($i > 6) {
      @if ($i < 21) {
        // create paddings from 35px to 100px with interval of 5
        .padding-#{$i*5},
        .p-#{$i*5} {
          padding: #{$i * 5}px !important;
          --padding: #{$i * 5}px !important;
        }
        .pt-#{$i*5} {
          padding-top: #{$i * 5}px !important;
          --padding-top: #{$i * 5}px !important;
        }
        .pr-#{$i*5} {
          padding-right: #{$i * 5}px !important;
          --padding-end: #{$i * 5}px !important;
        }
        .pb-#{$i*5} {
          padding-bottom: #{$i * 5}px !important;
          --padding-bottom: #{$i * 5}px !important;
        }
        .pl-#{$i*5} {
          padding-left: #{$i * 5}px !important;
          --padding-start: #{$i * 5}px !important;
        }

        // create margins from 35px to 100px with interval of 5
        .margin-#{$i*5},
        .m-#{$i*5} {
          margin: #{$i * 5}px !important;
        }
        .mt-#{$i*5} {
          margin-top: #{$i * 5}px !important;
        }
        .mr-#{$i*5} {
          margin-right: #{$i * 5}px !important;
        }
        .mb-#{$i*5} {
          margin-bottom: #{$i * 5}px !important;
        }
        .ml-#{$i*5} {
          margin-left: #{$i * 5}px !important;
        }
      }
    }
    // create font sizes from 1px to 30px
    .fs-#{$i} {
      font-size: #{$i}px !important;
    }
    @if ($i < 10) {
      // create font weights from 100 to 900 with interval of 100
      .fw-#{$i*100} {
        font-weight: #{$i * 100} !important;
      }
      // create font sizes from 0.1em to 0.9em with interval of 0.1
      /* TODO
      Enable this when we have imported the math from sass
       **/
      // .fs-0#{$i}em {
      //   font-size: #{$i/10}em;
      // }
    }
    @if ($i==10) {
      .fs1em {
        font-size: 1em !important;
      }
    }
  }
}

.pr-5em {
  padding-right: 5em !important;
}
.m-a {
  margin: auto;
}
.ml-a {
  margin-left: auto;
}
.mr-a {
  margin-right: auto;
}
.mt-a {
  margin-top: auto;
}
.mb-a {
  margin-bottom: auto;
}

.item-inner-padding-end-0 {
  --inner-padding-end: 0px !important;
}
.item-inner-padding-end-8 {
  --inner-padding-end: 8px !important;
}

// FONT SIZES, WEIGHTS
.fs-larger {
  font-size: larger;
}
.fw-bolder {
  font-weight: bolder;
}
.fs-xxl {
  font-size: xx-large;
}
.custom-icon-x-large {
  font-size: 94px;
}
.custom-icon-large {
  font-size: 64px;
}
.custom-icon-medium {
  font-size: 28px;
}
.custom-icon-small {
  font-size: 16px;
}

@media only screen and (max-width: $breakpoint-xsm) {
  .fs-14-xsm {
    font-size: 14px !important;
  }
}
// WIDTHS
.max-width-35-px {
  max-width: 35px !important;
}
.max-width-70-px {
  max-width: 70px;
}
.max-width-100-pct {
  max-width: 100% !important;
}
.min-width-100-pct {
  min-width: 100%;
}

.width-3-5-pct {
  width: 3.5%;
}
.width-8-pct {
  width: 8%;
}
.width-10-pct {
  width: 10%;
}
.width-12-5-pct {
  width: 12.5%;
}
.width-15-pct {
  width: 15%;
}
.width-20-pct {
  width: 20%;
}
.width-25-pct {
  width: 25%;
}
.width-40-pct {
  width: 40%;
}
.width-50-pct {
  width: 50%;
}
.width-70-pct {
  width: 70%;
}
.width-100-pct {
  width: 100%;
}
.width-110-px {
  width: 110px;
}
.width-200-px {
  width: 200px;
}

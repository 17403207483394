.text-3xl {
  font-size: var(--text-font-size-3xl) !important;
  font-weight: var(--text-font-weight-3xl) !important;
}
.text-xxl {
  font-size: var(--text-font-size-xxl) !important;
  font-weight: var(--text-font-weight-xxl) !important;
}
.text-xl {
  font-size: var(--text-font-size-xl) !important;
  font-weight: var(--text-font-weight-xl) !important;
}
.text-lg {
  font-size: var(--text-font-size-lg) !important;
  font-weight: var(--text-font-weight-lg) !important;
}
.text-md {
  font-size: var(--text-font-size-md) !important;
  font-weight: var(--text-font-weight-md) !important;
}
.text-sm {
  font-size: var(--text-font-size-sm) !important;
  font-weight: var(--text-font-weight-sm) !important;
}

.text-primary {
  color: var(--ion-color-text-primary) !important;
  --color: var(--ion-color-text-primary) !important;
}
.text-secondary {
  color: var(--ion-color-text-secondary) !important;
  --color: var(--ion-color-text-secondary) !important;
}
.text-brand-primary {
  color: var(--ion-color-brand-primary) !important;
  --color: var(--ion-color-brand-primary) !important;
}

.text-primary-3xl {
  @extend .text-3xl;
  color: var(--ion-color-text-primary) !important;
  --color: var(--ion-color-text-primary) !important;
}
.text-primary-xxl {
  @extend .text-xxl;
  color: var(--ion-color-text-primary) !important;
  --color: var(--ion-color-text-primary) !important;
}
.text-primary-xl {
  @extend .text-xl;
  color: var(--ion-color-text-primary) !important;
  --color: var(--ion-color-text-primary) !important;
}
.text-primary-lg {
  @extend .text-lg;
  color: var(--ion-color-text-primary) !important;
  --color: var(--ion-color-text-primary) !important;
}
.text-primary-md {
  @extend .text-md;
  color: var(--ion-color-text-primary) !important;
  --color: var(--ion-color-text-primary) !important;
}
.text-primary-sm {
  @extend .text-sm;
  color: var(--ion-color-text-primary) !important;
  --color: var(--ion-color-text-primary) !important;
}

.text-secondary-3xl {
  @extend .text-3xl;
  color: var(--ion-color-text-secondary) !important;
  --color: var(--ion-color-text-secondary) !important;
}
.text-secondary-xxl {
  @extend .text-xxl;
  color: var(--ion-color-text-secondary) !important;
  --color: var(--ion-color-text-secondary) !important;
}
.text-secondary-xl {
  @extend .text-xl;
  color: var(--ion-color-text-secondary) !important;
  --color: var(--ion-color-text-secondary) !important;
}
.text-secondary-lg {
  @extend .text-lg;
  color: var(--ion-color-text-secondary) !important;
  --color: var(--ion-color-text-secondary) !important;
}
.text-secondary-md {
  @extend .text-md;
  color: var(--ion-color-text-secondary) !important;
  --color: var(--ion-color-text-secondary) !important;
}
.text-secondary-sm {
  @extend .text-sm;
  color: var(--ion-color-text-secondary) !important;
  --color: var(--ion-color-text-secondary) !important;
}

.text-brand-primary-xxl {
  @extend .text-xxl;
  color: var(--ion-color-brand-primary) !important;
  --color: var(--ion-color-brand-primary) !important;
}
.text-brand-primary-xl {
  @extend .text-xl;
  color: var(--ion-color-brand-primary) !important;
  --color: var(--ion-color-brand-primary) !important;
}
.text-brand-primary-lg {
  @extend .text-lg;
  color: var(--ion-color-brand-primary) !important;
  --color: var(--ion-color-brand-primary) !important;
}
.text-brand-primary-md {
  @extend .text-md;
  color: var(--ion-color-brand-primary) !important;
  --color: var(--ion-color-brand-primary) !important;
}
.text-brand-primary-sm {
  @extend .text-sm;
  color: var(--ion-color-brand-primary) !important;
  --color: var(--ion-color-brand-primary) !important;
}

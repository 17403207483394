/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* SwitchDin fonts */
@import './styles/switchdin-fonts';

/** Ionic Application Variables **/
@import 'styles/ionic-application-variables.scss';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Material Theme */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material';

/* Custom style variables */
@import 'styles/custom-variables';
@import 'styles/custom-style-variables.scss';

/* Custom Styles */
@import './styles/animations';
@import './styles/buttons';
@import './styles/content-space';
@import './styles/custom-variable-colors.css';
@import './styles/old-design';
@import './styles/alerts-popover';
@import './styles/borders';
@import './styles/text-wrappers.scss';
@import './styles/dark-mode';
@import './styles/scroll';
@import './styles/statuses';
@import 'styles/custom-text-style.scss';
@import 'styles/custom-global-style.scss';
@import './styles/material';
@import './styles/user-inputs.scss';

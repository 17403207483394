.ion-color-brand-primary {
  --ion-color-base: var(--ion-color-brand-primary);
  --ion-color-base-rgb: var(--ion-color-brand-primary-rgb);
  --ion-color-contrast: var(--ion-color-brand-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-primary-shade);
  --ion-color-tint: var(--ion-color-brand-primary-tint);
}

.ion-color-text-primary {
  --ion-color-base: var(--ion-color-text-primary);
  --ion-color-base-rgb: var(--ion-color-text-primary-rgb);
  --ion-color-contrast: var(--ion-color-text-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-primary-shade);
  --ion-color-tint: var(--ion-color-text-primary-tint);
}

.ion-color-text-tertiary {
  --ion-color-base: var(--ion-color-text-tertiary);
  --ion-color-base-rgb: var(--ion-color-text-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-text-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-tertiary-shade);
  --ion-color-tint: var(--ion-color-text-tertiary-tint);
}

.ion-color-text-secondary {
  --ion-color-base: var(--ion-color-text-secondary);
  --ion-color-base-rgb: var(--ion-color-text-secondary-rgb);
  --ion-color-contrast: var(--ion-color-text-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-secondary-shade);
  --ion-color-tint: var(--ion-color-text-secondary-tint);
}

.ion-color-background-tertiary {
  --ion-color-base: var(--ion-color-background-tertiary);
  --ion-color-base-rgb: var(--ion-color-background-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-background-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-tertiary-shade);
  --ion-color-tint: var(--ion-color-background-tertiary-tint);
}

.ion-color-sub-tab-labels {
  --ion-color-base: var(--ion-color-sub-tab-labels);
  --ion-color-base-rgb: var(--ion-color-sub-tab-labels-rgb);
  --ion-color-contrast: var(--ion-color-sub-tab-labels-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sub-tab-labels-contrast-rgb);
  --ion-color-shade: var(--ion-color-sub-tab-labels-shade);
  --ion-color-tint: var(--ion-color-sub-tab-labels-tint);
}

.ion-color-solar {
  --ion-color-base: var(--ion-color-solar);
  --ion-color-base-rgb: var(--ion-color-solar-rgb);
  --ion-color-contrast: var(--ion-color-solar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-solar-contrast-rgb);
  --ion-color-shade: var(--ion-color-solar-shade);
  --ion-color-tint: var(--ion-color-solar-tint);
}

.ion-color-consumption {
  --ion-color-base: var(--ion-color-consumption);
  --ion-color-base-rgb: var(--ion-color-consumption-rgb);
  --ion-color-contrast: var(--ion-color-consumption-contrast);
  --ion-color-contrast-rgb: var(--ion-color-consumption-contrast-rgb);
  --ion-color-shade: var(--ion-color-consumption-shade);
  --ion-color-tint: var(--ion-color-consumption-tint);
}

.ion-color-battery {
  --ion-color-base: var(--ion-color-battery);
  --ion-color-base-rgb: var(--ion-color-battery-rgb);
  --ion-color-contrast: var(--ion-color-battery-contrast);
  --ion-color-contrast-rgb: var(--ion-color-battery-contrast-rgb);
  --ion-color-shade: var(--ion-color-battery-shade);
  --ion-color-tint: var(--ion-color-battery-tint);
}

/* Background colors */
.ion-color-background-primary {
  --ion-color-base: var(--ion-color-background-primary);
  --ion-color-base-rgb: var(--ion-color-background-primary-rgb);
  --ion-color-contrast: var(--ion-color-background-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-primary-shade);
  --ion-color-tint: var(--ion-color-background-primary-tint);
}
.--ion-color-background-secondary {
  --ion-color-base: var(--ion-color-background-secondary);
  --ion-color-base-rgb: var(--ion-color-background-secondary-rgb);
  --ion-color-contrast: var(--ion-color-background-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-secondary-shade);
  --ion-color-tint: var(--ion-color-background-secondary-tint);
}

.ion-color-button-primary {
  --ion-color-base: var(--ion-color-button-primary);
  --ion-color-base-rgb: var(--ion-color-button-primary-rgb);
  --ion-color-contrast: var(--ion-color-button-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-button-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-button-primary-shade);
  --ion-color-tint: var(--ion-color-button-primary-tint);
}
.ion-color-button-secondary {
  --ion-color-base: var(--ion-color-button-secondary);
  --ion-color-base-rgb: var(--ion-color-button-secondary-rgb);
  --ion-color-contrast: var(--ion-color-button-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-button-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-button-secondary-shade);
  --ion-color-tint: var(--ion-color-button-secondary-tint);
}
.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}
.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
}
.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}
/* Success, Warning, and Danger Lite */
.ion-color-success-lite {
  --ion-color-base: var(--ion-color-success-lite);
  --ion-color-base-rgb: var(--ion-color-success-lite-rgb);
  --ion-color-contrast: var(--ion-color-success-lite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-lite-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-lite-shade);
  --ion-color-tint: var(--ion-color-success-lite-tint);
}
.ion-color-warning-lite {
  --ion-color-base: var(--ion-color-warning-lite);
  --ion-color-base-rgb: var(--ion-color-warning-lite-rgb);
  --ion-color-contrast: var(--ion-color-warning-lite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-lite-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-lite-shade);
  --ion-color-tint: var(--ion-color-warning-lite-tint);
}
.ion-color-danger-lite {
  --ion-color-base: var(--ion-color-danger-lite);
  --ion-color-base-rgb: var(--ion-color-danger-lite-rgb);
  --ion-color-contrast: var(--ion-color-danger-lite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-lite-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-lite-shade);
  --ion-color-tint: var(--ion-color-danger-lite-tint);
}

.ion-color-primary-svg {
  --ion-color-base: var(--ion-color-primary-svg);
  --ion-color-base-rgb: var(--ion-color-primary-svg-rgb);
  --ion-color-contrast: var(--ion-color-primary-svg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-svg-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-svg-shade);
  --ion-color-tint: var(--ion-color-primary-svg-tint);
}

/* Tables Tile Color */
.ion-color-table-primary-tile {
  --ion-color-base: var(--ion-color-table-primary-tile);
  --ion-color-base-rgb: var(--ion-color-table-primary-tile-rgb);
  --ion-color-contrast: var(--ion-color-table-primary-tile-contrast);
  --ion-color-contrast-rgb: var(--ion-color-table-primary-tile-contrast-rgb);
  --ion-color-shade: var(--ion-color-table-primary-tile-shade);
  --ion-color-tint: var(--ion-color-table-primary-tile-tint);
}
.ion-color-table-secondary-tile {
  --ion-color-base: var(--ion-color-table-secondary-tile);
  --ion-color-base-rgb: var(--ion-color-table-secondary-tile-rgb);
  --ion-color-contrast: var(--ion-color-table-secondary-tile-contrast);
  --ion-color-contrast-rgb: var(--ion-color-table-secondary-tile-contrast-rgb);
  --ion-color-shade: var(--ion-color-table-secondary-tile-shade);
  --ion-color-tint: var(--ion-color-table-secondary-tile-tint);
}
.ion-color-table-gray-tile {
  --ion-color-base: var(--ion-color-table-gray-tile);
  --ion-color-base-rgb: var(--ion-color-table-gray-tile-rgb);
  --ion-color-contrast: var(--ion-color-table-gray-tile-contrast);
  --ion-color-contrast-rgb: var(--ion-color-table-gray-tile-contrast-rgb);
  --ion-color-shade: var(--ion-color-table-gray-tile-shade);
  --ion-color-tint: var(--ion-color-table-gray-tile-tint);
}

.scroll-y {
  overflow-y: scroll;
  overflow-x: hidden;
  // Firefox scrollbar
  scrollbar-color: rgba(var(--ion-color-brand-primary-rgb), 0.3) transparent;
}
// use a % of VH to allow for the top and/or bottom toolbars as required
@for $i from 70 through 100 {
  .scroll-y-#{$i} {
    @extend .scroll-y;
    max-height: #{$i}vh;
  }
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
*/
$prefix-path: '../assets/fonts';
@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1588733/3c02ef0f-3487-47fc-80ff-f187a773f58a.woff2') format('woff2'),
    url($prefix-path + '/1588733/a125edba-606c-4b37-ba1d-0c0e7487d491.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1583618/3af6507f-b340-46cb-a1da-db5935b4530d.woff2') format('woff2'),
    url($prefix-path + '/1583618/4f09c451-a45d-4981-a90e-533e9383b2d1.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1583760/8287344a-4cde-463e-9f06-9985ca85dba1.woff2') format('woff2'),
    url($prefix-path + '/1583760/84e1d10c-9475-4041-ac4f-0f89e6d33c2f.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1587566/57749238-ee23-4f06-a1c2-913e4fbc35a2.woff2') format('woff2'),
    url($prefix-path + '/1587566/636705aa-2371-4f49-b39c-ff59bbedece7.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1591190/dc836390-131e-464f-91e6-f9f9cf751a35.woff2') format('woff2'),
    url($prefix-path + '/1591190/dffa0023-f2da-40e3-9919-c09dcd31d1f3.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1581781/242030b7-dab4-4cdf-b2f0-81068ac58446.woff2') format('woff2'),
    url($prefix-path + '/1581781/e94d6a8d-a2f5-4530-94a2-0d0d14e13fc0.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1586764/166ce875-a649-4826-a5a0-a24f2dd89cd5.woff2') format('woff2'),
    url($prefix-path + '/1586764/fc417db9-6d91-4c85-81b1-2ff3d583d149.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1583189/a5a6b226-c201-4c7e-aa75-6d01874f1a83.woff2') format('woff2'),
    url($prefix-path + '/1583189/09560f34-5e51-44e7-962d-dbb751f7dee4.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1582343/5b6b0808-79db-4639-ab19-365f715f83b9.woff2') format('woff2'),
    url($prefix-path + '/1582343/0d9ceccf-0cd7-44dc-8c2a-dbc0ed90f149.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1583137/a865642b-c657-4a8c-b31c-3387daa11432.woff2') format('woff2'),
    url($prefix-path + '/1583137/2d64d960-d035-4629-99e9-5571504beb94.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1587810/91271893-3829-4dc9-a79b-a47b7146022c.woff2') format('woff2'),
    url($prefix-path + '/1587810/17dd7da6-7e09-45f6-bae4-9a944baed5a6.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1587114/b7fbd7d4-32a7-453d-84d3-1345247e48bb.woff2') format('woff2'),
    url($prefix-path + '/1587114/934be726-c31d-448f-9d56-76f74f872f91.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1581731/f409cb4c-a797-4b0b-9dc3-76a17288d995.woff2') format('woff2'),
    url($prefix-path + '/1581731/2ded309e-64e7-4bc7-b90e-af145940e5a5.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1583195/f8c1e2d4-d3f3-4f66-9f77-39692bf4c0c9.woff2') format('woff2'),
    url($prefix-path + '/1583195/7ca27657-e999-4936-b416-9c80b7c38d94.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1583706/51cafd8f-9166-4c3a-992b-901024545956.woff2') format('woff2'),
    url($prefix-path + '/1583706/d34b6bd9-57f6-45b8-bc42-8dcf2d1a6239.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: 'Switchdin';
  src:
    url($prefix-path + '/1586656/43093f69-cd8e-4554-b17a-c7d8ac508a06.woff2') format('woff2'),
    url($prefix-path + '/1586656/593cff70-eecc-4b5a-9b70-961965e4995e.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

/**
*
* The purpose of this file to hold the old design css
* will remove this if no longer needed / whole project is converted into new design
* non-used classes will be removed as we go
*
*/

// will remove when add devices is re-designed
.card-slide-layout {
  background-color: #f6f6f6;
  ion-card {
    background-color: white;
    width: 100%;
    border: var(--border-xsm);
    box-shadow: none;
    border-radius: 5px;
  }
}

// will remove when vpp re-designed
.bkgWhite {
  background-color: #ffffff !important;
}

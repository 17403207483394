// TODO: There are many un-necessary classes that needs to be removed
// legacy style needs to be removed
// there are multiple backgrounds - that are confusing and hard

ion-tab-bar {
  margin-bottom: env(safe-area-inset-bottom);
}

ion-searchbar {
  --border-radius: var(--input-border-radius) !important;
  --icon-color: var(--ion-color-primary-svg) !important;
  --color: var(--ion-color-text-primary) !important;
  .searchbar-input-container {
    input {
      font-size: 14px !important;
      padding-left: 12px !important;
      text-align: left !important;
      height: var(--input-height) !important;
    }
  }

  .searchbar-clear-button {
    background-color: var(--ion-item-background) !important;
    width: 25px;
    right: 13px !important;
    z-index: 100;
  }

  .searchbar-search-icon {
    right: 16px !important;
    left: auto !important;
  }
}
ion-popover {
  --width: 20rem;
}
ion-select-popover {
  overflow: auto;
}

.ion-item-border-height {
  border: var(--input-border-width) var(--input-border-type) var(--input-border-color);
  border-radius: var(--input-border-radius);
  height: var(--input-height);
  --height: var(--input-height);
  --min-height: var(--input-height);
}

.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 350px;
}

.links-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}

.text-deco-under {
  text-decoration: underline;
  font-size: 0.8em;
}
.text-deco-none {
  text-decoration: none;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-grow-1 {
  flex-grow: 1;
}

/***
* START NEW DESIGN TABS AND THINGS
***/

// TODO: need to rename this, have the same name for below class
// need to see how it is impacting that one

.new-design {
  ion-tabs {
    background: var(--ion-color-background-primary);
    --background: var(--ion-color-background-primary);

    ion-tab-bar {
      display: flex;
      justify-content: space-around;
      border: transparent var(--border-type) var(--border-width-xsm) !important;
      width: calc(min(500px, 90vw));
      height: 70px;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0 10px;
      border-radius: 124px;
      background: var(--ion-item-background) !important;
      --background: var(--ion-item-background) !important;
      --ion-color-base: var(--ion-item-background) !important;
      box-shadow:
        0px -3px 20px rgba(190, 193, 197, 0.18),
        0px 11px 15px rgba(190, 193, 197, 0.2);
      transition: all 0.5s;
      ion-tab-button {
        background: transparent !important;
        font-size: calc(min(12px, 2.5vw));
        --ripple-color: rgba(0, 0, 0, 0);
        --color-selected: rgba(var(--ion-color-brand-primary-rgb), 0.5);
      }
    }
  }

  .tab-selected {
    color: var(--ion-color-primary-svg);
  }

  background-color: var(--ion-color-background-primary);
  --background: var(--ion-color-background-primary);
  padding-top: 12px;

  .background-color {
    background-color: var(--ion-color-background-primary);
    --background: var(--ion-color-background-primary);
  }

  .sc-ion-searchbar-md-h {
    --box-shadow: none !important;
    padding-inline-start: 0px !important;
  }
}
.max-width-wide {
  max-width: var(--max-width-wide);
}
// .static-tab {
//   position: static !important;
//   contain: none !important;
// }

/***
* END NEW DESIGN TABS AND THINGS
***/

.alert-input-group-no-scroll {
  .alert-wrapper {
    .alert-checkbox-group {
      max-height: fit-content !important;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .large-modal .modal-wrapper {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
}
@media not screen and (max-width: $breakpoint-md) {
  .large-modal .modal-wrapper {
    border-radius: 12px;
    height: 90vh;
    width: 50vw;
  }
}

ion-modal::part(content) {
  @media screen and (min-width: $breakpoint-md) {
    --background: transparent !important;
    --box-shadow: none !important;
  }
  @media (min-width: $breakpoint-md) {
    min-height: calc(100vh - 2rem);
  }
}

.transparent-modal .modal-wrapper {
  background: transparent;
  --box-shadow: none;
  box-shadow: none;
}
.bkgWhite {
  background-color: #ffffff !important;
}

.alert-min-width-30-pct {
  .alert-wrapper.sc-ion-alert-md {
    min-width: 30%;
  }
  .alert-checkbox-group.sc-ion-alert-md {
    max-height: 500px;
  }
  .alert-radio-label.sc-ion-alert-ios,
  .alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
}

.alert-min-width-50-pct {
  .alert-wrapper.sc-ion-alert-md {
    min-width: 50%;
  }
  .alert-checkbox-group.sc-ion-alert-md {
    max-height: 400px;
  }
  .alert-radio-label.sc-ion-alert-ios,
  .alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
}

.alert-min-width-70-pct {
  .alert-wrapper.sc-ion-alert-md {
    min-width: 70%;
  }
  .alert-radio-label.sc-ion-alert-ios,
  .alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
}

// TODO: change name to kebab-case
.ionSelectAlertLabelTextWrap {
  .item.sc-ion-label-md-h,
  .item .sc-ion-label-md-h {
    white-space: pre-line !important;
  }
  .item.sc-ion-label-ios .item .sc-ion-label-ios-h {
    white-space: pre-line !important;
  }
  .popover-content.sc-ion-popover-md,
  .popover-content.sc-ion-popover-ios {
    width: 90%;
    max-width: 500px;
    @media screen and (max-width: $breakpoint-sm) {
      max-width: 90%;
    }
    @media screen and (max-width: $breakpoint-md) {
      max-width: 80%;
    }
  }
}

.row-selected,
.ion-item-selected {
  background: #cbcbcb !important;
  --background: #cbcbcb !important;
}
.bkg-transparent {
  background: transparent !important;
  --background: transparent !important;
}
.bkg-theme,
.background-color {
  background-color: var(--ion-color-background-primary);
  --background: var(--ion-color-background-primary);
}
.background-color-md-max {
  @media only screen and (max-width: $breakpoint-md) {
    background-color: var(--ion-color-background-primary);
  }
}

// classes moved from equipment tab in here
.portfolio-name {
  font-size: smaller;
  color: var(--ion-color-medium);
}
.endpoint-uuid {
  color: var(--ion-color-medium);
}

.iconColor {
  color: #757575;
}

.firstRowHeader th {
  font-size: medium;
  font-weight: 600;
}
.sencondRowHeader th {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 600;
}

.scroll-x-auto {
  overflow-x: auto;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
// TODO
//  there is no need for new-card-design just with a height in it
//  need to go through the app and see where ever using this class
//  see if height cause any issues, than use fullHeight class there
.card-design {
  border-radius: 12px;
  border: none;
  box-shadow: $box-shadow;
}
.new-design-card {
  @extend .card-design;
  height: 100%;
}

.card-small-subheader {
  text-align: right;
  font-size: 14px;
  color: $text-secondary;
}

.text-light-o {
  color: $text-tertiary;
  opacity: $text-opacity;
}

.text-light {
  color: $text-tertiary !important;
}

.text-medium {
  color: $text-secondary !important;
}

.text-dark {
  color: $text-primary !important;
}

.text-brand-primary {
  color: $brand-primary !important;
}

.light-brand-primary-div-background {
  background-color: rgba(var(--ion-color-brand-primary-rgb), 0.05);
}

.light-secondary-three-div-background {
  background-color: rgba(70, 150, 236, 0.05);
}

.light-text-tertiary-div-background {
  background-color: rgba(var(--ion-color-text-tertiary-rgb), 0.02);
}

.card-title {
  font-weight: 600;
  font-size: 22px;
  color: $text-primary;
}

.lh-1 {
  line-height: 1;
}
.lh-20 {
  line-height: 20px;
}
.lh-100-pct {
  line-height: 100%;
}
.lh-120-pct {
  line-height: 120%;
}
.zIndex101 {
  z-index: 101 !important;
}

.main-label {
  font-size: 32px !important;
  line-height: 37px;
  letter-spacing: -0.03em;
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 22px !important;
    line-height: 26.4px !important;
    font-weight: 600;
  }
}

// HEIGHTS
.fullHeight,
.full-height,
.fh-row {
  height: 100%;
}
.autoHeight {
  height: auto !important;
}
.minHeightFull {
  min-height: 100%;
}
.minHeight160 {
  min-height: 160px;
}
.minHeightUnset {
  --min-height: unset;
}
.mh-100 {
  min-height: 100%;
}

//JUSTIFICATIONS
.align-top {
  vertical-align: top;
}

.grayed-out {
  opacity: 0.2;
}

//
// Droplet Notification
//
.droplet-notification {
  --background: var(--droplet-notification-bg);
  --border-radius: var(--droplet-notification-border-radius);
  --min-height: var(--droplet-notification-min-height);
  --padding-start: var(--droplet-notification-padding-start);
  --inner-padding-top: var(--droplet-notification-padding-vert);
  --inner-padding-bottom: var(--droplet-notification-padding-vert);

  &.success {
    --background: var(--droplet-notification-bg-success);
  }

  &.warning {
    margin-top: var(--droplet-notification-vert-spacing);
    --background: var(--droplet-notification-bg-warning);
  }

  &.danger {
    --background: var(--droplet-notification-bg-danger);
  }

  ion-text {
    font-size: var(--droplet-notification-text-size);
    color: var(--droplet-notification-text-col);

    p {
      font-size: var(--droplet-notification-secondary-text-size);
      color: var(--droplet-notification-secondary-text-col);
    }
  }

  ion-icon {
    height: var(--droplet-notification-icon-height);
    width: var(--droplet-notification-icon-width);
    margin-right: var(--droplet-notification-icon-margin-end);
  }

  // large notification (larger padding and border radius)
  &.large {
    --inner-padding-top: var(--droplet-notification-padding-lg);
    --inner-padding-bottom: var(--droplet-notification-padding-lg);
    --padding-start: var(--droplet-notification-padding-lg);
    //--padding-end: var(--droplet-notification-padding-lg); additional padding on end not required?
    --border-radius: var(--droplet-notification-border-radius-lg);
  }
}
.base-input {
  border: var(--input-border-width) var(--input-border-type) var(--input-border-color);
  border-radius: var(--input-border-radius);
  font-size: var(--input-text-font-size) !important;
  font-weight: var(--input-text-font-weight) !important;
  min-height: var(--input-height);
  height: var(--input-height);

  --color: var(--ion-color-text-primary);
  --height: var(--input-height);
  --padding-start: var(--input-text-padding-start) !important;
  --placeholder-font-weight: var(--input-placeholder-font-weight);
}

.ion-item-no-horizontal-padding {
  --inner-padding-end: 0;
  --padding-start: 0;
}

.custom-popover-style {
  --width: 15rem;
  --min-width: 15rem;
  margin-top: 1.1rem;
  ion-checkbox {
    --checkbox-background-checked: var(--ion-color-button-secondary);
    --border-color-checked: var(--ion-color-button-secondary);
    --checkmark-color: var(--ion-color-button-secondary-contrast);
    color: var(--ion-color-text-primary);
  }
  .checkbox-checked {
    color: var(--ion-color-button-secondary);
  }
}

// google places autocomplete list styling
.pac-container {
  background-color: var(--ion-item-background);
}
.pac-item {
  font-family: 'Switchdin';
  font-size: var(--text-font-size-lg);
  color: var(--ion-color-text-primary);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
.pac-item-query,
.pac-matched {
  font-family: 'Switchdin';
  font-size: var(--text-font-size-lg);
  color: var(--ion-color-text-primary);
}
.pac-icon {
  padding-left: 5px;
  padding-right: 5px;
}

ion-toast.custom-app-toast-notification {
  margin-top: 4rem;
  --border-radius: 6px;
}
ion-toast.custom-app-toast-notification::part(header) {
  font-size: var(--text-font-size-xl);
  font-weight: var(--text-font-weight-xl);
}
ion-toast.custom-app-toast-notification::part(message) {
  font-size: var(--text-font-size-lg);
  font-weight: var(--text-font-weight-lg);
}
.unit-deleted-state-opacity {
  opacity: 0.6;
}
.app-loading-spinner {
  height: 6rem;
  width: 6rem;
  background: var(--ion-item-background);
  border-radius: 50%;
  box-shadow: var(--device-config-ion-spinner-shadow-box);
}

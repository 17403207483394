/**
* BUTTONS STYLES
*
* Plan is to hold all the styles related the buttons in here
*
*/

.edit-name-button {
  height: var(--button-height-xsm);
  min-height: var(--button-height-xsm);
  --box-shadow: var(--button-shadow);
  box-shadow: var(--button-shadow);
}

.default-button-md {
  --border-radius: var(--button-border-radius-md);
  height: var(--button-height-lg);
  text-transform: capitalize;
  font-size: var(--button-font-size-md);
}

/**
*
* Will hold all the statuses - active, not active, some are active
* maybe other stuff for alerts as well, but haven't thought about that yet
*
*/
.portfolio-status {
  margin: 2px;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.portfolio-status-online {
  background-color: var(--ion-color-success);
}
.portfolio-status-some {
  background-color: var(--ion-color-warning);
}
.portfolio-status-offline {
  background-color: var(--ion-color-danger);
}
.portfolio-status-unconfig::after {
  content: '';
  background-color: white;
  position: absolute;
  top: 25%;
  left: 25%;
  height: 50%;
  width: 50%;
  border-radius: 50%;
}

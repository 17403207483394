:root {
  --max-width-wide: 1400px;

  --border-type: solid;
  --border-color-opacity: 0.2;
  --border-width-xsm: 1px;
  --border-width-sm: 2px;
  --border-width-md: 3px;
  --border-width-lg: 4px;
  --border-width-xlg: 5px;
  --border-width-xxl: 6px;

  --border-xsm: var(--border-width-xsm) var(--border-type) var(--ion-border-color);
  --border-sm: var(--border-width-sm) var(--border-type) var(--ion-border-color);
  --border-md: var(--border-width-md) var(--border-type) var(--ion-border-color);
  --border-lg: var(--border-width-lg) var(--border-type) var(--ion-border-color);
  --border-xlg: var(--border-width-xlg) var(--border-type) var(--ion-border-color);
  --border-xxl: var(--border-width-xxl) var(--border-type) var(--ion-border-color);

  --input-border-color: #e7e8e9;
  --input-border-radius: 6px;
  --input-border-type: var(--border-type);
  --input-border-width: var(--border-width-xsm);
  --input-focus-border-color: var(--ion-color-brand-primary);
  --input-focus-border-width: var(--border-width-sm);
  --input-focus-border-color-invalid: var(--ion-color-danger);

  --text-font-size-3xl: 30px;
  --text-font-size-xxl: 22px;
  --text-font-size-xl: 20px;
  --text-font-size-lg: 16px;
  --text-font-size-md: 14px;
  --text-font-size-sm: 13px;

  --text-font-weight-3xl: 700;
  --text-font-weight-xxl: 600;
  --text-font-weight-xl: 600;
  --text-font-weight-lg: 500;
  --text-font-weight-md: 400;
  --text-font-weight-sm: 400;

  --input-text-color: var(--ion-color-text-primary);
  --input-text-font-weight: 600;
  --input-text-font-size: 14px;
  --input-text-padding-start: 12px;

  --input-label-color: var(--ion-color-text-primary);
  --input-label-font-size: 13px;
  --input-label-font-weight: 400;

  --input-placeholder-font-weight: 500;
  --input-placeholder-color: var(--ion-color-text-tertiary);
  --input-height: 45px;

  --button-border-width: 1px;
  --button-border-radius-xsm: 6px;
  --button-border-radius-sm: 8px;
  --button-border-radius-md: 8px;
  --button-border-radius-lg: 12px;
  --button-border-radius-rounded-sm: 37px;
  --button-border-radius-rounded-md: 59px;
  --button-font-size-md: 16px;

  --button-height-xsm: 32px;
  --button-height-sm: 35px;
  --button-height-md: 42px;
  --button-height-lg: 53px;

  --button-width-sm: 85px;
  --button-min-width-sm: 85px;

  --button-shadow: 0px 12px 14px -10px rgba(32, 32, 36, 0.04);
  --spinner-shadow: 0px 3.64px 80.06px rgba(var(--ion-color-brand-primary-rgb), 0.4);

  --button-icon-height: 20px;
  --button-icon-position: absolute;

  // Droplet Notifications
  --droplet-notification-text-size: 13px;
  --droplet-notification-text-col: var(--ion-color-text-primary);
  --droplet-notification-secondary-text-size: 11px;
  --droplet-notification-secondary-text-col: var(--ion-color-text-tertiary);
  --droplet-notification-vert-spacing: 10px;
  --droplet-notification-padding-vert: 4px;
  --droplet-notification-padding-start: 10px;
  --droplet-notification-min-height: 23px;
  --droplet-notification-border-radius: 6px;
  --droplet-notification-border-radius-lg: 12px;
  --droplet-notification-bg: rgba(var(--ion-color-text-secondary-rgb), 0.1);
  --droplet-notification-bg-success: var(--ion-color-success-lite);
  --droplet-notification-bg-warning: var(--ion-color-warning-lite);
  --droplet-notification-bg-danger: var(--ion-color-danger-lite);
  --droplet-notification-icon-height: 12px;
  --droplet-notification-icon-width: 12px;
  --droplet-notification-icon-margin-end: 10px;
  --droplet-notification-padding-lg: 15px;

  --device-config-ion-spinner-shadow-box: 0px 4px 4px 0px #a0bcb730;
}

.alert {
  width: 100%;
  padding: 15px;
  border: var(--border-width-xsm) var(--border-type) transparent;
  border-radius: 0px 0px 4px 4px;
  &.alert-danger {
    color: var(--ion-color-danger);
    background-color: var(--ion-color-danger-lite);
  }
  &.alert-info {
    background-color: rgba(var(--ion-color-brand-primary-rgb), 0.05);
    --background: rgba(var(--ion-color-brand-primary-rgb), 0.05);
  }
  &.alert-item-info {
    --background: rgba(var(--ion-color-brand-primary-rgb), 0.05);
  }
  &.alert-item-warning {
    --background: rgba(var(--ion-color-warning-rgb), 0.05);
  }
}

.alert-min-width-30-pct {
  .alert-wrapper.sc-ion-alert-md {
    min-width: 30%;
  }

  .alert-checkbox-group.sc-ion-alert-md {
    max-height: 500px;
  }

  .alert-radio-label.sc-ion-alert-ios,
  .alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
}

.alert-min-width-50-pct {
  .alert-wrapper.sc-ion-alert-md {
    min-width: 50%;
  }

  .alert-checkbox-group.sc-ion-alert-md {
    max-height: 400px;
  }

  .alert-radio-label.sc-ion-alert-ios,
  .alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
}

.alert-min-width-70-pct {
  .alert-wrapper.sc-ion-alert-md {
    min-width: 70%;
  }

  .alert-radio-label.sc-ion-alert-ios,
  .alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }
}

.ionSelectAlertLabelTextWrap {
  .item.sc-ion-label-md-h,
  .item .sc-ion-label-md-h {
    white-space: pre-line !important;
  }

  .item.sc-ion-label-ios .item .sc-ion-label-ios-h {
    white-space: pre-line !important;
  }

  .popover-content.sc-ion-popover-md {
    width: 300px !important;
  }

  .popover-content.sc-ion-popover-ios {
    width: 300px !important;
  }
}

.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
  white-space: normal !important;
}
.alert-tappable.sc-ion-alert-md,
.alert-tappable.sc-ion-alert-ios {
  height: auto;
  contain: content;
}

.recipients-alert {
  .alert-checkbox-label.sc-ion-alert-md {
    white-space: pre-line !important;
  }

  .recipients-alert .alert-checkbox-label.sc-ion-alert-ios {
    white-space: pre-line !important;
  }

  .alert-wrapper {
    min-width: 300px;
  }
}

.transparent-backdrop-popover {
  margin-top: 10px;
  --backdrop-opacity: 0;

  .popover-wrapper {
    --box-shadow: 0px 10px 48px -10.6687px rgba(57, 124, 120, 0.4);
    .popover-arrow {
      z-index: 1000;
    }
  }
}

.notification-alert {
  .alert-wrapper {
    border-radius: 12px !important;
  }

  a {
    color: var(--ion-color-brand-primary) !important;
  }
}

.notification-alert-button {
  color: var(--ion-color-brand-primary) !important;
}

// <app-modal-container> modal need to be 100% height so we get full page background
// setting the height to 95vh to avoid having multiple scrolls on the screen / button on the
// bottom was getting hidden as it gets 100% height
app-modal-container {
  height: 95vh;
}

.base-simple-user-input {
  --background: var(--ion-item-background);
  --border-radius: var(--input-border-radius) !important;
  --border-style: var(--input-border-type);
  --border-width: var(--input-border-width);
  --color: var(--ion-color-text-primary);
  color: var(--ion-color-text-primary);
  --height: var(--input-height);
  // --padding-start: var(--input-text-padding-start) !important;
  --placeholder-font-weight: var(--input-placeholder-font-weight);
  --placeholder-color: var(--input-placeholder-color);
  --placeholder-opacity: 0.8;
  --highlight-color-focused: var(--input-focus-border-color) !important;
  --highlight-color-invalid: var(--input-focus-border-color-invalid) !important;
  --highlight-color-valid: var(--input-focus-border-color) !important;
  // --padding-bottom:	Bottom padding of the input
  // --padding-end:	Right padding if direction is left-to-right, and left padding if direction is right-to-left of the input
  // --padding-top:	Top padding of the input
  // --placeholder-font-style:	Font style of the input placeholder text
  min-height: var(--input-height) !important;

  font-size: var(--input-text-font-size) !important;
  font-weight: var(--input-text-font-weight) !important;
}

.simple-user-input {
  @extend .base-simple-user-input;
  --border-color: var(--input-border-color);
}
.simple-user-input-label {
  --color: var(--input-label-color) !important;
  color: var(--input-label-color) !important;
  font-size: var(--input-label-font-size) !important;
  font-weight: var(--input-label-font-weight) !important;
  transform: none !important;
}

.simple-user-select-input {
  @extend .base-simple-user-input;
  --padding-start: var(--input-text-padding-start) !important;
  --padding-end: var(--input-text-padding-start) !important;
}

.simple-user-toggle-input-label {
  font-size: var(--input-text-font-size) !important;
  font-weight: var(--input-text-font-weight) !important;
  --color: var(--input-label-color) !important;
  color: var(--input-label-color) !important;
}

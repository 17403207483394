/**
* This file will contain all the Dark Mode stuff
*
*/

/*
 *
 * Dark Colors
 * -------------------------------------------
 */
.common-dark-mode {
  --ion-color-text-primary: white !important;
  --ion-color-text-tertiary: white !important;

  --input-label-color: white !important;
  --input-text-color: white !important;
  --input-placeholder-color: white !important;
  --input-border-color: #3b3d40;

  --ion-color-sub-tab-labels: white !important;
  --ion-color-sub-tab-labels-contrast: white !important;
  --ion-color-table-gray-tile: #26292d;

  ion-searchbar {
    --background: var(--ion-item-background) !important;
  }
  ion-item-divider {
    background-color: #1a1c1f;
  }

  .mat-mdc-table {
    background: var(--ion-item-background);
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      color: var(--ion-color-text-primary);
    }
    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
      border-bottom-color: var(--ion-border-color);
    }
  }
  tr:nth-child(even) {
    background: var(--ion-color-light-shade);
  }
  tr:nth-child(odd) {
    background: inherit;
  }
  .mat-mdc-paginator {
    color: var(--ion-color-text-primary);
    background: var(--ion-item-background);
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
    .mat-mdc-icon-button.mat-button-disabled.mat-button-disabled {
      color: #222421;
    }
  }
}
body.dark {
  @extend .common-dark-mode;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-background-primary: #0a0d0e;
  --ion-color-background-primary-rgb: 10, 13, 14;
  --ion-color-background-primary-contrast: #ffffff;
  --ion-color-background-primary-contrast-rgb: 255, 255, 255;
  --ion-color-background-primary-shade: #090b0c;
  --ion-color-background-primary-tint: #232526;
  --ion-item-background: #1a1c1f;
}

/*
* iOS Dark Theme
* -------------------------------------------
*/
.ios body.dark {
  @extend .common-dark-mode;
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  --ion-toolbar-background: #0d0d0d;
  --ion-item-background: #1a1c1f;
}

/*
* Material Design Dark Theme
* -------------------------------------------
*/
.md body.dark {
  @extend .common-dark-mode;
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-item-background: #1a1c1f;
  --ion-toolbar-background: #1f1f1f;
  --ion-tab-bar-background: #1f1f1f;
}
